<template>
    <div>
        <div class="msg">
            <div>上海爱复盘互联网信息服务有限公司 沪ICP备2024102133号-1  Copyright © 2024 爱复盘 All Rights Reserved.</div>
        </div>
    </div>
</template>

<script>
import homePage from './component/home-page.vue';
import solution from './component/solution.vue';
import download from './component/download.vue';
import aboutUs from './component/about-us.vue';

export default {
    name: 'ReplayOfficialIndex',
    components:{
        homePage,
        solution,
        download,
        aboutUs
    },
    data() {
        return {
            currentMenuValue:'homePage',
            menuList: [
                {label: '首页', name: 'homePage'},
                {label: '解决方案', name: 'solution'},
                {label: '下载', name: 'download'},
                {label: '关于我们', name: 'aboutUs'},
            ]
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>
<style>
    .msg{
        background-color: #F6F7F9;
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center;
        padding: 24px 0px;
        color: #666666;
        font-size: 14px;
    }
</style>