<template>
    <div>
        <div>
            <div style="position: relative;">
                <div style="position: absolute;margin-top: 10px; left: 15%;width: 8%;">
                    <img src="@/assets/imgs/logo.png" style="width: 100%;">
                </div>
                <img src="@/assets/imgs/download1.png" style="width: 100%;">
                <div style="position: absolute;bottom: 0%;width: 100%;display: flex;flex-direction: column;align-items: center;">
                    <div style="color: #EF523E;font-size: 0.84vw;margin-bottom: 0.9vw;">注意：下载安装前，请务必关掉电脑上的360杀毒软件或其他杀毒软件，以防误报</div>
                    <div  @click="download" style="width: 24%;border-radius: 16px;">
                        <hover style="width: 100%"></hover>
                    </div>
                    <div style="color: #687583;font-size: 0.84vw;margin: 1vw 0px 2.2vw 0px;">仅支持Windows10以上系统</div>
                </div>
            </div>
            <img src="@/assets/imgs/download2.png" style="width: 100%;">
            <img src="@/assets/imgs/download3.png" style="width: 100%;">
        </div>
    </div>
</template>

<script>
import hover from '../hover.vue';
export default {
    components:{hover},
    data() {
        return {
            installerUrl:'https://download.aifupan.com.cn/aifupan1.9.10.exe',
            installerName:'爱复盘',
        };
    },
    methods: {
        download(){
            const link = document.createElement('a');
            link.href = this.installerUrl;
            link.download = this.installerName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

    },
};
</script>
<style>
    .msg{
        background-color: #F6F7F9;
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center;
        padding: 24px 0px;
        color: #666666;
        font-size: 14px;
    }

    .download-btn{
        transition: all linear 0.3s;
    }

    .download-btn:hover{
        cursor: pointer;
        box-shadow: 0 6px 20px rgba(12, 100, 242);
        transform: translateY(-3px);
    }
</style>