<template>
    <div class="index-c">
        <div style="position: relative;">
            <div style="position: absolute;margin-top: 10px; left: 15%;width: 8%;">
                <img src="@/assets/imgs/logo.png" style="width: 100%;">
            </div>
            <div>
                <div style="position: absolute;margin-top: 10px; left: 38%;">
                    <el-tabs v-model="currentMenuValue" @tab-click="handleTabClick">
                        <el-tab-pane v-for="item in menuList" :key="item.value" :label="item.label" :name="item.name">
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <!-- 首页 -->
                <homePage v-if="currentMenuValue =='homePage'"></homePage>
                <!-- 解决方案 -->
                <solution v-if="currentMenuValue =='solution'"></solution>
                <!-- 下载 -->
                <download v-if="currentMenuValue =='download'"></download>
                <!-- 关于我们 -->
                <aboutUs v-if="currentMenuValue =='aboutUs'"></aboutUs>
            </div>

            <div style="position: absolute;top: 13px;right: 5%;">
                <el-button @click="kefuVisible = true" type="primary" size="small" style="width: 100%; border-radius: 8px;">免费试用</el-button>
            </div>
        </div>
        <!--  -->
        <msg></msg>
        <!-- 弹框 -->
        <div v-if="kefuVisible" style="position: fixed;bottom: 1%;right: 1.5%;width: 13%;">
            <div @click="kefuVisible = false" class="kefu-style" style="position: absolute;top: 5%;right: 6%;width: 6%;">
                <img src="@/assets/imgs/close.png" style="width: 100%;">
            </div>
            <img src="@/assets/imgs/kefu.png" style="width: 100%;border: 1px solid #ccc;border-radius: 8px;">
        </div>
        <!-- 点击回到顶部 -->
        <el-backtop target=".index-c" right=30 bottom=30 :visibility-height="200" style="background-color: #D4F3FF;width: 2.3%;">
        </el-backtop>
    </div>
</template>

<script>
import homePage from './component/home-page.vue';
import solution from './component/solution.vue';
import download from './component/download.vue';
import aboutUs from './component/about-us.vue';
import msg from './msg.vue';

export default {
    name: 'ReplayOfficialIndex',
    components:{
        homePage,
        solution,
        download,
        aboutUs,
        msg,
    },
    data() {
        return {
            kefuVisible:false,
            currentMenuValue:'homePage',
            menuList: [
                {label: '首页', name: 'homePage'},
                {label: '解决方案', name: 'solution'},
                {label: '下载', name: 'download'},
                {label: '关于我们', name: 'aboutUs'},
            ]
        };
    },
    created(){
        const page = localStorage.getItem('page');
        if(page){
            this.currentMenuValue = page;
            localStorage.setItem('page','');
        }
    },
    methods: {
    },
};
</script>

<style scoped>
    .index-c{
        min-width: 700px;
        height: 100%;
        overflow-x: hidden;
    }

    .kefu-style:hover{
        cursor: pointer;
    }
    .btn{
        width: 100%;
        color: #ffffff;
        background-color: #0077FF;
        text-align: center;
        border-radius: 8px;
    }
    .btn:hover{
        cursor: pointer;
    }

    
    ::v-deep .el-tabs__item {
        color: #5c6f86;
    }
    
    /*去掉tabs底部的下划线*/
    ::v-deep .el-tabs__nav-wrap::after {
        position: static;
    }
</style>