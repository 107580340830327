<template>
    <div class="image-container">
      <img :src="currentImage" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        originalImage: require('@/assets/imgs/download4.png'), // 替换为你的原始图片路径
        hoverImage: require('@/assets/imgs/download5.png'), // 替换为你的悬停图片路径
        currentImage: require('@/assets/imgs/download4.png') // 初始化为原始图片
      };
    },
    methods: {
      onMouseOver() {
        this.currentImage = this.hoverImage;
      },
      onMouseLeave() {
        this.currentImage = this.originalImage;
      }
    }
  };
  </script>
  
  <style scoped>
  .image-container {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  
  img:hover{
    transform: translateY(-3px);
    
  }
  
  img{
    width: 100%;
    height: auto;
    display: block;
    transition: all 0.5s linear;
  }
  
  </style>