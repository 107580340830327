<template>
    <div>
        <div>
            <div style="position: relative;display: flex;justify-content: center;">
                <div style="position: absolute;margin-top: 10px; left: 15%;width: 8%;">
                    <img src="@/assets/imgs/logo.png" style="width: 100%;">
                </div>
                <div style="position: absolute;bottom: 2%;left: 18.8%;width: 100%;">
                    <div class="home-download" @click="download">下载Windows版本</div>
                    <div style="color: #687583;font-size: 0.84vw;margin: 1vw 0px 0.84vw 0px;">仅支持Windows10以上系统</div>
                    <div style="color: #EF523E;font-size: 0.84vw;">注意：下载安装前，请务必关掉电脑上的360杀毒软件或其他杀毒软件，以防误报</div>
                </div>
                <img src="@/assets/imgs/home_page1.png" style="width: 100%;">
            </div>
            <div style="position: relative;display: flex;justify-content: center;">
                <video class="video-style" :src="videoSrc" controls ></video>
                <img src="@/assets/imgs/home_page2.png" style="width: 100%;">
            </div>
            <img src="@/assets/imgs/home_page3.png" style="width: 100%;">
            <img src="@/assets/imgs/home_page4.png" style="width: 100%;">
            <img src="@/assets/imgs/home_page5.png" style="width: 100%;">
            <img src="@/assets/imgs/home_page6.png" style="width: 100%;">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            videoSrc:"https://download.aifupan.com.cn/promotion.mp4",
            installerUrl:'https://download.aifupan.com.cn/aifupan1.9.10.exe',
            installerName:'爱复盘',
        };
    },

    mounted() {

    },

    methods: {
        download(){
            const link = document.createElement('a');
            link.href = this.installerUrl;
            link.download = this.installerName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
};
</script>

<style>
    .video-style{
        /* width: 32%; */
        height: 44.5%;
        border: 0.5px solid #eee;
        position: absolute;
        top: 20.5%;
    }

    .msg{
        background-color: #F6F7F9;
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center;
        padding: 24px 0px;
        color: #666666;
        font-size: 14px;
    }


    .home-download{
        width: 12vw; 
        border-radius: 8px;
        font-size: 1.1vw;
        background-color: #0077FF;
        color: #FFFFFF;
        text-align: center;
        line-height: 2.5vw;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .home-download:hover{
        transform: translateY(-3px);
        color: #6ee6fe;
    }
</style>