<template>
    <div>
        <div>
            <img src="@/assets/imgs/solution1.png" style="width: 100%;">
            <img src="@/assets/imgs/solution2.png" style="width: 100%;">
            <img src="@/assets/imgs/solution3.png" style="width: 100%;">
            <img src="@/assets/imgs/solution4.png" style="width: 100%;">
            <img src="@/assets/imgs/solution5.png" style="width: 100%;">
            <img src="@/assets/imgs/solution6.png" style="width: 100%;">
            <img src="@/assets/imgs/solution7.png" style="width: 100%;">
            <img src="@/assets/imgs/solution8.png" style="width: 100%;">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style>
    .msg{
        background-color: #F6F7F9;
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center;
        padding: 24px 0px;
        color: #666666;
        font-size: 14px;
    }
</style>