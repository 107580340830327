<template>
    <div>
        <div>
            <img src="@/assets/imgs/about_us1.png" style="width: 100%">
            <img src="@/assets/imgs/about_us2.png" style="width: 100%">
            <img src="@/assets/imgs/about_us3.png" style="width: 100%">
            <img src="@/assets/imgs/about_us4.png" style="width: 100%">
            <img src="@/assets/imgs/about_us5.png" style="width: 100%">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>
<style>
    .msg{
        background-color: #F6F7F9;
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center;
        padding: 24px 0px;
        color: #666666;
        font-size: 14px;
    }
</style>